@import url("https://fonts.googleapis.com/css?family=Caveat:700|Quicksand");

.App {
  text-align: center;
}

body {
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  align-items: center;
  justify-content: center;
  font-size: 24px;
  z-index: 5;
  position: relative;
  padding-top: 12%;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 5%;
  font-family: 'Quicksand', sans-serif;
  color: lightgrey;
  margin: 0 auto;
  max-width: 750px;
}

.App-link {
  color: #61dafb;
}

.Background {
  position: fixed; 
  top: -50%; 
  left: -50%; 
  width: 200%; 
  height: 200%;
}

.Background-img {
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  margin: auto; 
  width: auto;
  height: auto;
  z-index: -1;
}

.Footer {
  position: relative;
  z-index: 1;
  color: grey;
  font-size: 12px;
}

a {
  color: green;
}

a:hover {
  color: lightgreen;
}

.Form-input {
  position: absolute; 
  left: -5000px;
}

#mc_embed_signup {
  z-index: 5;
  position: relative;
  font-family: 'Quicksand', sans-serif;
  color: lightgrey;
}

#mc-embedded-subscribe-form {
  display: grid;
}

.Input {
  width: 75%;
  max-width: 500px;
  padding: 12px 20px;
  margin: 8px 0px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.Input-button {
  width: 75%;
  max-width: 500px;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px;
  margin-bottom: 200px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}